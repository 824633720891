var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',[_c('div',{staticClass:"custom-container"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-items-start"},[_c('h2',{staticClass:"card-title"},[_c('b',[_vm._v("REGISTRO GPS")])])])]},proxy:true}])},[_c('div',{staticClass:"px-5 py-3"},[_c('div',{staticClass:"row",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"col-12 col-md-10"},[_c('CardTable',{attrs:{"data":_vm.dataCards}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('BtnAddCardTable',{attrs:{"text":"Nuevo GPS","url":"gps_agregar"}})],1)])]),_c('div',{staticClass:"px-4 mt-3"},[_c('table',{staticClass:"table table-bordered table-responsive-md table-striped text-center",attrs:{"id":"datatable_registroGPS"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Imei")]),_c('th',[_vm._v("Marca")]),_c('th',[_vm._v("Modelo")]),_c('th',[_vm._v("Fecha Compra")]),_c('th',[_vm._v("Más Detalles")]),_c('th',[_vm._v("Asignado")]),_c('th',[_vm._v("Activo")])])]),_c('tbody',_vm._l((_vm.listaItems),function(item,index){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(item.imei)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.marca)+" ")]),_c('td',[_vm._v(_vm._s(item.modelo))]),_c('td',[_vm._v(_vm._s(_vm._f("timeSince")(item.fechaCompra)))]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('vs-button',{directives:[{name:"b-modal",rawName:"v-b-modal.detailsGps",modifiers:{"detailsGps":true}}],attrs:{"icon":""},on:{"click":function($event){return _vm.filterData(item)}}},[_c('i',{staticClass:"fas fa-eye"})])],1)]),_c('td',[_c('h4',[_c('b-badge',{attrs:{"variant":`${
                          item.gpsActivo && item.gpsAsignado
                            ? 'success'
                            : !item.gpsActivo
                            ? 'danger'
                            : 'info'
                        } `}},[_c('i',{class:`fas ${
                            item.gpsActivo && item.gpsAsignado
                              ? 'fa-car'
                              : !item.gpsActivo
                              ? 'fa-circle-xmark'
                              : 'fa-bolt'
                          }`}),_vm._v(" "+_vm._s(item.gpsActivo && item.gpsAsignado ? `${item.placaVehiculo}` : !item.gpsActivo ? "Inactivo" : "Disponible")+" ")])],1)]),_c('td',[[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('vs-switch',{attrs:{"disabled":item.gpsAsignado},on:{"input":function($event){return _vm.toggleActivo(index, item)}},scopedSlots:_vm._u([{key:"off",fn:function(){return [_c('i',{staticClass:"fas fa-xmark"})]},proxy:true},{key:"on",fn:function(){return [_c('i',{staticClass:"fas fa-check"})]},proxy:true}],null,true),model:{value:(item.gpsActivo),callback:function ($$v) {_vm.$set(item, "gpsActivo", $$v)},expression:"item.gpsActivo"}})],1)]],2)])}),0)])]),_c('b-modal',{attrs:{"title":"Detalles GPS","id":"detailsGps","body-bg-variant":"dark","size":"lg","hide-footer":"","hide-header":"","body-class":"body-modal-custom"}},[_c('Gps_detail',{attrs:{"gps":_vm.filterGps,"updateDataAssign":_vm.updateDataAssign,"updateDataLiberate":_vm.updateDataLiberate}})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }